'use strict';

var $ = require('../internals/export');
var global = require('../internals/global');
var setTask = require('../internals/task').set;
var schedulersFix = require('../internals/schedulers-fix');

// https://github.com/oven-sh/bun/issues/1633
var setImmediate = global.setImmediate ? schedulersFix(setTask, false) : setTask;

// `setImmediate` method
// http://w3c.github.io/setImmediate/#si-setImmediate
$({
  global: true,
  bind: true,
  enumerable: true,
  forced: global.setImmediate !== setImmediate
}, {
  setImmediate: setImmediate
});