import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Handsontable from 'handsontable';
import { CellChange } from 'handsontable/common';
import { Subject, takeUntil, tap } from 'rxjs';
import { MekiScheduleTablePartnerRow, MekiScheduleTablePodRow } from '../../../meki-sek-schedules/interfaces';
import { ScheduleType } from '../../../../shared/enums';
import { ScheduleResponse } from '../../interfaces/responses';
import { ScheduleTimeSeriesRequest } from '../../interfaces/requests';
import Core from 'handsontable/core';
import { CellProperties } from 'handsontable/settings';
import { baseRenderer } from 'handsontable/renderers';
import {
  calculateInBudapestTimeZone,
  generateQuarterHourLabelsForDayInBudapestTimeZone,
  isBefore,
  isEqual,
} from '../../../../shared/utils/dates';

@Component({
  selector: 'app-sek-schedule-table[tableData]',
  templateUrl: './sek-schedule-table.component.html',
  styleUrls: ['./sek-schedule-table.component.scss'],
})
export class SekScheduleTableComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  @ViewChild(`sekScheduleTableContainer`, { static: true })
  public container!: ElementRef<HTMLDivElement>;

  @Input()
  public tableData!: Subject<{
    schedule?: ScheduleResponse;
    scheduleType: ScheduleType;
    date: Date;
    readOnly?: boolean;
  }>;

  @Input()
  public tableIsValid = new Subject<boolean>();

  @Input()
  public tableHasChanged = new Subject<boolean>();

  @Input()
  public toScheduleRequest = new Subject<'change' | 'overwrite'>();

  @Input()
  public scheduleRequest = new Subject<ScheduleTimeSeriesRequest[]>();

  /** The Handsontable instance displaying the loaded data. */
  private handsonTable!: Handsontable;

  /** Should contain the original table data before any changes. */
  public tableSource: (MekiScheduleTablePodRow | MekiScheduleTablePartnerRow)[] = [];

  /** Contains invalid cell indexes in the form of `<row>-<col>`. */
  private invalidCells = new Set<string>();

  /**
   * Maps the changed cell keys to the changes. Key format is `<row>-<property>`, where `row` is the row number in the
   * table, and the `property` is the property path of associated with the column, as we use custom data objects instead
   * of arrays.
   * E.g. key: `0-schedules.SEK_KE.scheduleValue`, `12-schedules.EON_TO_SEK.scheduleValue`, etc...
   *
   * This map should keep track  of each change made to the table.
   */
  private cellChanges = new Map<string, CellChange>();

  /**
   * Should be filled, after date is selected. Contains quarter-hour labels as `HH:MM-HH:MM [MW]`.
   * E.g: 01:15-01:30 [MW], 14:30-14:45 [MW], 23:45-00:00 [MW], etc...
   * For most of the days it contains 24 * 4 = 96 elements, but
   * on DST start day an hour is missing so 23 * 4 = 92, and on DST end day there is an extra hour so 25 * 4 = 100.
   */
  private quarterHourLabels: string[] = [];

  private date!: Date;

  private scheduleType!: ScheduleType;

  private readOnly!: boolean;

  public ngOnInit(): void {
    this.handsonTable = new Handsontable(this.container.nativeElement, {
      data: [],
      stretchH: 'all',
      fixedColumnsStart: 0,
      comments: true,
      licenseKey: 'non-commercial-and-evaluation',
      afterChange: changes => {
        for (const change of changes ?? []) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const [row, prop, , newValue] = change;

          const originalValue = this.tableSource[row].schedules[(prop as string).split('.')[1]].scheduleValue;

          const changeKey = `${row}-${prop as string | number}`;

          if (originalValue !== newValue) {
            this.cellChanges.set(changeKey, change);
          } else {
            this.cellChanges.delete(changeKey);
          }
        }

        this.tableHasChanged.next(this.cellChanges.size > 0);
      },
    });

    this.tableData
      .pipe(
        takeUntil(this.onDestroy),
        tap(({ schedule, scheduleType, date, readOnly }) => {
          this.cellChanges.clear();
          this.invalidCells.clear();
          this.readOnly = !!readOnly;
          this.scheduleType = scheduleType;
          if (!isEqual(this.date, date)) {
            this.date = date;
            this.quarterHourLabels = generateQuarterHourLabelsForDayInBudapestTimeZone({ date, suffix: ' [MW]' });
          }
          this.transformDataForComponent(schedule);
        })
      )
      .subscribe();

    this.toScheduleRequest
      .pipe(
        takeUntil(this.onDestroy),
        tap(mode => {
          if (mode === 'change') {
            this.scheduleRequest.next(this.generateChangeRequest());
          } else if (mode === 'overwrite') {
            this.scheduleRequest.next(this.generateOverwriteRequest());
          }
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.handsonTable?.destroy();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  /**
   * This function is the **unified** entrypoint for changing data in the component. It handles
   * all required changes and checks we need to do when setting data received from the API.
   */
  private transformDataForComponent(schedule: ScheduleResponse | undefined = undefined) {
    if (schedule === undefined) {
      this.updateTableConfig([]);
      return;
    }

    const timeBlocksToSchedulesMapping = new Map<string, MekiScheduleTablePartnerRow | MekiScheduleTablePodRow>();
    schedule.timeSeries.forEach(timeBlock => {
      timeBlock.values.forEach(timeBlockValue => {
        const rowData =
          timeBlocksToSchedulesMapping.get(timeBlockValue.timeBlockStart) ??
          ({
            tableType: this.scheduleType === ScheduleType.A02 ? 'partner' : 'pod',
            timeBlockStart: new Date(timeBlockValue.timeBlockStart),
            schedules: {},
          } as MekiScheduleTablePodRow | MekiScheduleTablePartnerRow);

        rowData.schedules[timeBlock.senderIdentification] =
          this.scheduleType === ScheduleType.A02
            ? {
                inPartyEicCode: timeBlock.inPartyEicCode as string,
                outPartyEicCode: timeBlock.outPartyEicCode as string,
                scheduleValue: timeBlockValue.value,
              }
            : {
                meteringPointId: timeBlock.meteringPointId as string,
                scheduleValue: timeBlockValue.value,
              };

        timeBlocksToSchedulesMapping.set(timeBlockValue.timeBlockStart, rowData);
      });
    });

    this.updateTableConfig(Array.from(timeBlocksToSchedulesMapping.values()));
  }

  private updateTableConfig(scheduleTableRows: (MekiScheduleTablePodRow | MekiScheduleTablePartnerRow)[]): void {
    /**
     * This check is necessary since with async fetching data the situation can happen, where we call the handsontable
     * destroy method before this update method is executed.
     */
    if (this.handsonTable.isDestroyed) {
      return;
    }

    this.tableSource = structuredClone(scheduleTableRows);
    if (scheduleTableRows.length === 0) {
      this.handsonTable.updateSettings({ data: [], columns: [] });
      return;
    }

    const tableType = scheduleTableRows[0].tableType;

    const senderIdentifications: string[] = [];
    const directions: string[] = [];
    const partners: string[] = [];
    const podCodes: string[] = [];
    const sinergyEicCode = '15X-SINERGY----D';

    for (const senderIdentification of Object.keys(scheduleTableRows[0].schedules)) {
      if (tableType === 'partner') {
        senderIdentifications.push(senderIdentification);
        const schedule = scheduleTableRows[0].schedules[senderIdentification];
        if (schedule.inPartyEicCode === sinergyEicCode) {
          directions.push('IMPORT');
          partners.push(schedule.outPartyEicCode);
        } else if (schedule.outPartyEicCode === sinergyEicCode) {
          directions.push('EXPORT');
          partners.push(schedule.inPartyEicCode);
        }
      } else {
        senderIdentifications.push(senderIdentification);
        podCodes.push(scheduleTableRows[0].schedules[senderIdentification].meteringPointId);
      }
    }

    const nestedHeaders =
      tableType === 'partner' ? [senderIdentifications, directions, partners] : [senderIdentifications, podCodes];

    const firstEditableQuarterHour = this.firstEditableQuarterHour();

    this.handsonTable.updateSettings({
      data: scheduleTableRows,
      maxCols: senderIdentifications.length,
      minCols: senderIdentifications.length,
      maxRows: scheduleTableRows.length,
      minRows: scheduleTableRows.length,
      rowHeaderWidth: 130,
      rowHeaders: [...this.quarterHourLabels],
      nestedHeaders,
      columns: this.getHandsonTableColumns(scheduleTableRows),
      cells: row => {
        const rowData = this.handsonTable.getSourceData()[row] as MekiScheduleTablePodRow | MekiScheduleTablePartnerRow;

        return {
          readOnly: this.readOnly || isBefore(rowData.timeBlockStart, firstEditableQuarterHour),
        };
      },
    });
  }

  /**
   * Validates the cells and return with the table column.
   *
   * @param scheduleTableRows The rows of the schedule table.
   */
  private getHandsonTableColumns(scheduleTableRows: (MekiScheduleTablePodRow | MekiScheduleTablePartnerRow)[]) {
    return Object.keys(scheduleTableRows[0].schedules).map(senderIdentification => {
      return {
        data: `schedules.${senderIdentification}.scheduleValue`,
        type: 'numeric',
        renderer: (
          instance: Core,
          td: HTMLTableCellElement,
          row: number,
          col: number,
          prop: string | number,
          currentValue: string | null,
          cellProperties: CellProperties
        ) => {
          const errorKey = `${row}-${col}`;
          let errorMessage = '';

          cellProperties.className = 'htRight';

          if (
            currentValue === '' ||
            currentValue === null ||
            Number(currentValue) < 0 ||
            Number.isNaN(Number(currentValue))
          ) {
            this.invalidCells.add(errorKey);
            errorMessage = `Value must be a positive number.`;
          } else {
            this.invalidCells.delete(errorKey);
          }

          if (errorMessage !== '') {
            cellProperties.comment = {
              value: errorMessage,
              readOnly: true,
              style: {
                width: 300,
                height: 30,
              },
            };
            cellProperties.valid = false;
          } else {
            cellProperties.comment = {
              value: '',
              readOnly: true,
              style: {
                width: 300,
                height: 30,
              },
            };
            cellProperties.valid = true;
          }

          this.tableIsValid.next(this.invalidCells.size === 0);

          td.innerText = currentValue ?? '';
          baseRenderer.apply(this, [instance, td, row, col, prop, currentValue, cellProperties]);
        },
      };
    });
  }

  /**
   * Calculates the first editable quarter-hour.
   *
   * If the selected schedule type is `A02 (domestic trade)`, then there is no lead-time,
   * thus the first editable quarter-hour is the next quarter-hour.
   *
   * If the selected schedule type is either `A01 (production)`, or `A04 (consumption)`, then the lead-time is 30 minutes,
   * thus the first editable quarter-hour is the next quarter-hour plus 30 minutes.
   */
  private firstEditableQuarterHour(): Date {
    const leadTimeInMinutes = this.scheduleType === ScheduleType.A02 ? 0 : 30;

    const now = new Date();
    const quarterHourInMillis = 15 * 60 * 1000;
    const millisToNextQuarterHour = quarterHourInMillis - (now.getTime() % quarterHourInMillis);
    const nextQuarterHour = calculateInBudapestTimeZone(now, shiftedDate =>
      shiftedDate.plus({ milliseconds: millisToNextQuarterHour })
    );

    return calculateInBudapestTimeZone(nextQuarterHour, shiftedDate =>
      shiftedDate.plus({ minutes: leadTimeInMinutes })
    );
  }

  private generateChangeRequest(): ScheduleTimeSeriesRequest[] {
    /**
     * The key is either the `meteringPointId`, or `inPartyEicCode-outPartyEicCode`.
     * For each key, the value collects each delta changes.
     */
    const timeSeriesMapping = new Map<string, ScheduleTimeSeriesRequest>();
    const partnerTable = this.tableSource[0].tableType === 'partner';

    /**
     *  Each row (quarter-hour) contains a value for each `senderIdentification` (POD/tradePartner), but MEKI expects
     *  the delta values grouped by `senderIdentification`, so we have to transform the grouping from hourly grouping
     *  to `senderIdentification` grouping.
     *
     *  E.g.: If we have `pod-1` with 2 modified quarter-hours, and `pod-2` with one modified quarter-hour, then the
     *  table source looks like:
     *  rows = [{
     *      timeBlockStart: '2023-03-05T02:30:00.000Z',
     *      schedules: {
     *          'sender-identification-pod-1': {
     *              meteringPointId: 'pod-1',
     *              value: 2
     *          },
     *          'sender-identification-pod-2': {
     *              meteringPointId: 'pod-2',
     *              value: 3
     *          }
     *      }
     *  },
     *  {
     *      timeBlockStart: '2023-03-05T02:45:00.000Z',
     *      schedules: {
     *          'sender-identification-pod-1': {
     *              meteringPointId: 'pod-1',
     *              value: 5
     *          }
     *      }
     *  }]
     *
     *  But MEKI expects it in the following format:
     *  {
     *      timeSeries: [
     *          {
     *              meteringPointId: 'pod-1',
     *              values: [
     *                  {
     *                      timeBlockStart: '2023-03-05T02:30:00.000Z',
     *                      value: 2
     *                  },
     *                  {
     *                      timeBlockStart: `2023-03-05T02:45:00.000Z`,
     *                      value: 5
     *                  }
     *              ]
     *          },
     *          {
     *              meteringPointId: 'pod-2',
     *              values: [
     *                  {
     *                      timeBlockStart: '2023-03-05T02:30:00.000Z',
     *                      value: 3
     *                  }
     *              ]
     *          }
     *      ]
     *  }
     */
    for (const [row, prop, , newValue] of Array.from(this.cellChanges.values())) {
      const timeBlockStart = this.tableSource[row].timeBlockStart;
      const sourceSchedule =
        /**
         * Split of `prop` is necessary, as it follows the form: `schedules.<senderIdentification>.scheduleValue`,
         * and we only need the `<senderIdentification>` part of it.
         */
        this.tableSource[row].schedules[(prop as string).split('.')[1]];

      if (partnerTable) {
        /** If partner table, then the schedule contains EIC codes. */
        const { inPartyEicCode, outPartyEicCode } = sourceSchedule as MekiScheduleTablePartnerRow['schedules'][string];
        const key = `${inPartyEicCode}-${outPartyEicCode}`;
        const timeSeries = timeSeriesMapping.get(key) ?? {
          inPartyEicCode,
          outPartyEicCode,
          values: [],
        };
        timeSeries.values.push({
          timeBlockStart,
          value: newValue as number,
        });
        timeSeriesMapping.set(key, timeSeries);
      } else {
        const { meteringPointId } = sourceSchedule as MekiScheduleTablePodRow['schedules'][string];
        const key = `${meteringPointId}`;
        const timeSeries = timeSeriesMapping.get(key) ?? {
          meteringPointId,
          values: [],
        };
        timeSeries.values.push({
          timeBlockStart,
          value: newValue as number,
        });
        timeSeriesMapping.set(key, timeSeries);
      }
    }

    return Array.from(timeSeriesMapping.values());
  }

  private generateOverwriteRequest(): ScheduleTimeSeriesRequest[] {
    /**
     * The key is either the `meteringPointId`, or `inPartyEicCode-outPartyEicCode`.
     * For each key, the value collects each delta changes.
     */
    const timeSeriesMapping = new Map<string, ScheduleTimeSeriesRequest>();

    /**
     *  Each row (quarter-hour) contains a value for each `senderIdentification` (POD/tradePartner), but MEKI expects
     *  the delta values grouped by `senderIdentification`, so we have to transform the grouping from hourly grouping
     *  to `senderIdentification` grouping.
     *
     *  E.g.: If we have `pod-1` with 2 modified quarter-hours, and `pod-2` with one modified quarter-hour, then the
     *  table source looks like:
     *  rows = [{
     *      timeBlockStart: '2023-03-05T02:30:00.000Z',
     *      schedules: {
     *          'sender-identification-pod-1': {
     *              meteringPointId: 'pod-1',
     *              value: 2
     *          },
     *          'sender-identification-pod-2': {
     *              meteringPointId: 'pod-2',
     *              value: 3
     *          }
     *      }
     *  },
     *  {
     *      timeBlockStart: '2023-03-05T02:45:00.000Z',
     *      schedules: {
     *          'sender-identification-pod-1': {
     *              meteringPointId: 'pod-1',
     *              value: 5
     *          }
     *      }
     *  }]
     *
     *  But MEKI expects it in the following format:
     *  {
     *      timeSeries: [
     *          {
     *              meteringPointId: 'pod-1',
     *              values: [
     *                  {
     *                      timeBlockStart: '2023-03-05T02:30:00.000Z',
     *                      value: 2
     *                  },
     *                  {
     *                      timeBlockStart: `2023-03-05T02:45:00.000Z`,
     *                      value: 5
     *                  }
     *              ]
     *          },
     *          {
     *              meteringPointId: 'pod-2',
     *              values: [
     *                  {
     *                      timeBlockStart: '2023-03-05T02:30:00.000Z',
     *                      value: 3
     *                  }
     *              ]
     *          }
     *      ]
     *  }
     */
    for (const sourceRow of this.handsonTable.getSourceData() as (
      | MekiScheduleTablePodRow
      | MekiScheduleTablePartnerRow
    )[]) {
      const timeBlockStart = sourceRow.timeBlockStart;
      const partnerTable = sourceRow.tableType === 'partner';

      if (partnerTable) {
        for (const schedule of Object.keys(sourceRow.schedules)) {
          /** If partner table, then the schedule contains EIC codes. */
          const { inPartyEicCode, outPartyEicCode, scheduleValue: value } = sourceRow.schedules[schedule];
          const key = `${inPartyEicCode}-${outPartyEicCode}`;
          const timeSeries = timeSeriesMapping.get(key) ?? {
            inPartyEicCode,
            outPartyEicCode,
            values: [],
          };
          timeSeries.values.push({
            timeBlockStart,
            value,
          });
          timeSeriesMapping.set(key, timeSeries);
        }
      } else {
        for (const schedule of Object.keys(sourceRow.schedules)) {
          const { meteringPointId, scheduleValue: value } = sourceRow.schedules[schedule];
          const key = `${meteringPointId}`;
          const timeSeries = timeSeriesMapping.get(key) ?? {
            meteringPointId,
            values: [],
          };
          timeSeries.values.push({
            timeBlockStart,
            value,
          });
          timeSeriesMapping.set(key, timeSeries);
        }
      }
    }

    return Array.from(timeSeriesMapping.values());
  }
}
